import React, { useState } from 'react';
import { useMutation } from '@apollo/client'
import { GET_USER_DATA } from 'queries';
import { SET_PROPERTY } from 'queries'
import formStyles from 'assets/styles/Form.module.scss'
import {PopupButton} from 'components/ui/Popup'
import { profileSettings } from '../../../../conf';

interface Props {
  motivation?: string
  close?: () => void;
}

export const GoalsForm:React.FC<Props> = ({
  motivation,
  close,
  ...props
}) => {
  const [motivationTmp, handleClick] = useState(motivation);

  const [saveGoals, {loading}] = useMutation(SET_PROPERTY, { 
    onCompleted() {
      close();
    }
  });

  const submit = async (event) => {
    event.preventDefault()
    const variables = {key: "motivation", value: motivationTmp}
    try {
      await saveGoals({variables, refetchQueries: [GET_USER_DATA]})
    } catch (error) {
      alert(error)
    }
  }
  
  

  return <form  className={formStyles.form} onSubmit={submit}>
    <div className={formStyles.optioninputs}>
      <label className={formStyles.optioninput}>
        <input type="radio" name="motivation" value="health" required
          checked={motivationTmp === "health"}
          onChange={() => handleClick('health')} />
        <div>
          <h3>{profileSettings.motivation.health.title}</h3> &mdash; {profileSettings.motivation.health.text}
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="motivation" value="keep" required
          checked={motivationTmp === "keep"}
          onChange={() => handleClick('keep')} />
        <div>
          <h3>{profileSettings.motivation.keep.title}</h3> &mdash; {profileSettings.motivation.keep.text}
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="motivation" value="lose" required
          checked={motivationTmp === "lose"}
          onChange={() => handleClick('lose')} />
        <div>
          <h3>{profileSettings.motivation.lose.title}</h3> &mdash; {profileSettings.motivation.lose.text}
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="motivation" value="kickstart" required
          checked={motivationTmp === "kickstart"}
          onChange={() => handleClick('kickstart')} />
        <div>
          <h3>{profileSettings.motivation.kickstart.title}</h3> &mdash; {profileSettings.motivation.kickstart.text}
        </div>
      </label>
    </div>

    <button disabled={loading} type="submit">Lagre</button>
  </form>
}



export default function GoalsButton(props) {
  return(
    <PopupButton  title="Program" show={props.show} {...props}>
      <GoalsForm {...props}  />
    </PopupButton>
  )
}