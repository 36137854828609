import moment from 'moment'
const profileSettings = {
  motivation: {
    health: {
      title: 'Overskudd',
      text: 'Føl deg bedre, kvikkere, friskere',
      verb: 'spise for overskudd',
    },
    keep: {
      title: 'Hold vekten',
      text: 'For deg som nylig har gått ned i vekt',
      verb: 'holde vekten'
    },
    lose: {
      title: 'Ned i vekt - balansert',
      text: 'Få nye vaner som varer',
      verb: 'ned i vekt'
    },
    kickstart: {
      title: 'Ned i vekt - kickstart',
      text: 'Raskt vekttap på kort tid',
      verb: 'ned i vekt raskt'
    },
    maintain: {
      title: 'Hold vekten',
      text: 'For deg som nylig har gått ned i vekt',
      verb: 'holde vekten'
    }
  },
  health: {
    hasDiabetes: 'Diabetes',
    hasHighCholesterol: 'Høyt kolesterol',
    hasHypertension: 'Høyt blodtrykk',
    hasHypothyroidism: 'Lavt stoffskifte',
    isBreastFeeding: 'Ammende',
    isPregnant: 'Gravid',
  },
  allergy: {
    title: 'Har du noen \n matallergier?',
    choices: {
      isAllergicToEgg: 'Egg',
      isAllergicToFish: 'Fisk',
      isAllergicToGluten: 'Gluten',
      isAllergicToMilk: 'Melk',
      isAllergicToLactose: 'Laktoseintolerant',
      isAllergicToNut: 'Nøtter',
      isAllergicToShellFish: 'Skalldyr'
    }
  },
  active: {
    activityTitle: 'Hverdagsaktivitet',
    activities: {
      low: 'Lav: Inaktiv hverdag. Under 5000 skritt per dag.',
      moderate: 'Moderat: Noe aktivitet. 5000-8000 skritt per dag.',
      high: 'Høy: Aktiv hverdag. Over 8000 skritt per dag.'
    },
    exerciseHabitsTitle: 'Svett trening per uke',
    exerciseHabits: {
      veryLow: 'Svært lav (0-1 t/uke)',
      low: 'Lavt (2-3 t/uke)',
      moderate: 'Moderat (3-5 t/uke)',
      high: 'Høyt (6-8 t/uke)',
      veryHigh: 'Svært høyt (mer enn 9 t/uke)'
    },
    title: 'Hvor fysisk\naktiv er du?',
    instruction:
      'For at vi skal kunne gi deg en personlig anbefaling for hvor mye du bør spise, trenger vi å vite hvor aktiv du er. Er du allerede registrert hos oss, så sjekk at dataene stemmer med dagens aktivitetsnivå.'
  },
  membership: {
    roedeGold: 'Medlemskap og kurs',
    roedeStandard: 'Medlemskap og kurs',
  },
  goalBmiTips: {
    BMI_LE_18_5:
      'Din BMI vil bli $TARGET_BMI som betyr undervekt. Du bør veie litt mer.',
    BMI_GE_18_6_AND_BMI_LE_19_99:
      'Din BMI vil bli $TARGET_BMI som er normalvekt, men likevel litt lavt. Er du sikker på om kroppen din vil trives her?',
    BMI_GE_20_0_AND_BMI_LE_22_99:
      'Din BMI vil bli $TARGET_BMI som er normalvekt. Flott! Her vil du og kroppen din trives.',
    BMI_GE_23_0_AND_BMI_LE_24_99:
      'Din BMI vil bli $TARGET_BMI som er normalvekt. Flott! Kommer du hit vil du få bedre helse og oppleve mer overskudd og energi.',
    BMI_GE_25_0_AND_BMI_LE_26_99:
      'Din BMI vil bli $TARGET_BMI som fortsatt er lett overvektig, men veier du mye i dag, oppnår du uansett bedre helse ved å komme hit.',
    BMI_GE_27_0_AND_BMI_LE_29_99:
      'Din BMI vil bli $TARGET_BMI som fortsatt er overvekt, men veier du mye i dag vil et vekttap på 5 - 10 % gi deg bedre helse.',
    BMI_GE_30:
      'Din BMI vil bli $TARGET_BMI som fortsatt er fedme, men veier du mye i dag kan dette være et første skritt på veien mot bedre helse.'
  },
  currentBmiTips: {
    BMI_LE_18_5:
      'Din BMI er $TARGET_BMI som er i området for undervekt. Du vil oppnå en helsegevinst av å gå noe opp i vekt.',
    BMI_GE_18_6_AND_BMI_LE_19_99:
      'Din BMI er $TARGET_BMI som er i nedre område for normalvekt. Du bør unngå vektnedgang, og kan ha helsegevinst av å gå noe opp i vekt.',
    BMI_GE_20_0_AND_BMI_LE_22_99:
      'Din BMI er $TARGET_BMI som er i området for normalvekt. Fortsett som du gjør med å spise sunt og være i aktivitet.',
    BMI_GE_23_0_AND_BMI_LE_24_99:
      'Din BMI er $TARGET_BMI som er i området for normalvekt. Fortsett som du gjør med å spise sunt og være i aktivitet. Ønsker du å justere litt på vekten er det små grep som skal til for å lykkes.',
    BMI_GE_25_0_AND_BMI_LE_26_99:
      'Din BMI er $TARGET_BMI som er i området for lett overvekt. Det viktigste for deg er å hindre videre vektøkning. Ønsker du å gå noe ned i vekt er det små grep som skal til for å lykkes.',
    BMI_GE_27_0_AND_BMI_LE_29_99:
      'Din BMI er $TARGET_BMI som er i området for overvekt. Du vil få en lettere hverdag med mer overskudd og bedre helse av å gå ned i vekt.',
    BMI_GE_30:
      'Din BMI er $TARGET_BMI som er i området for fedme. Du vil få en lettere hverdag med mer overskudd og bedre helse av å gå ned i vekt.'
  },
  polarConfig: {
    DELETED: "Koble sammen med polar for å få trening og skritt automatisk registrert i treningsloggen.",
    ACCEPTED: "Du er koblet sammen med Polar-bruker ($1).",
    REQUESTED_BY_ORG: "Du må bekrefte <a href='https://flow.polar.com/settings/authorizations' target='_blank' class='partner_request' rel='noopener noreferrer'>partner forespørsel</div>",
    DENIED: "Avvist instead of nektet"
  },
  menuCards: {
    0: 'https://www.roede.com/filemanager/6/download',
    1: 'https://www.roede.com/filemanager/7/download',
    2: 'https://www.roede.com/filemanager/8/download',
    3: 'https://www.roede.com/filemanager/9/download',
    4: 'https://www.roede.com/filemanager/10/download',
    5: 'https://www.roede.com/filemanager/11/download'
  }


};

export default profileSettings;


export const  getGender = (gender) => {
  if(gender === "male") return "Mann";
  if(gender === "female") return "Kvinne";
  return "n/a";
}



export const getActivities = (key) => {
  const mapper = {
    '1.3': 'low',
    '1.4': 'moderate',
    '1.5': 'high'
  }

  return key in mapper ? profileSettings.active.activities[mapper[key]] : "n/a";
}

export const getExerciseHabits = (key) => {
  const mapper = {
    '2': 'veryLow',
    '5': 'low',
    '10': 'moderate',
    '15': 'high',
    '20': 'veryHigh'
  }

  return key in mapper ? profileSettings.active.exerciseHabits[mapper[key]] : "n/a";
}

export const getMotivation = (userData) => {
  if(userData?.motivation in profileSettings.motivation) {
    const motivation = profileSettings.motivation[userData.motivation];
    return motivation.title + " - " + motivation.text;
  } else {
    return 'Hva er ditt mål?';
  }
}

export const getMotivationVerb = (userData) => {
  if(userData?.motivation in profileSettings.motivation) {
    const motivation = profileSettings.motivation[userData.motivation];
    return motivation.verb;
  } else {
    return '?';
  }
}


export const getAge = (userData) => {
  if(userData?.birthDate) {
    return moment().diff(userData.birthDate, 'years') + " år";
  } else {
    return "n/a"
  }
}




export const round5 = (x) => {
  return (x % 5) >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
}
