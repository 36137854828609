import React, {useState} from 'react';
import { useMutation } from '@apollo/client'
import styles from '../Popup.module.scss';
import { UPDATE_WEIGHT_GOAL } from 'queries';
import { GET_USER_DATA } from 'queries';
import { profileSettings } from '../../../../conf';
import formStyles from 'assets/styles/Form.module.scss'
import {PopupButton} from 'components/ui/Popup'

const TIPS_INFO = [
  {
    rule: bmi => bmi <= 18.5,
    textKey: 'BMI_LE_18_5'
  },
  {
    rule: bmi => bmi >= 18.6 && bmi <= 19.99,
    textKey: 'BMI_GE_18_6_AND_BMI_LE_19_99'
  },
  {
    rule: bmi => bmi >= 20.0 && bmi <= 22.99,
    textKey: 'BMI_GE_20_0_AND_BMI_LE_22_99'
  },
  {
    rule: bmi => bmi >= 23.0 && bmi <= 24.99,
    textKey: 'BMI_GE_23_0_AND_BMI_LE_24_99'
  },
  {
    rule: bmi => bmi >= 25.0 && bmi <= 26.99,
    textKey: 'BMI_GE_25_0_AND_BMI_LE_26_99'
  },
  {
    rule: bmi => bmi >= 27.0 && bmi <= 29.99,
    textKey: 'BMI_GE_27_0_AND_BMI_LE_29_99'
  },
  {
    rule: bmi => bmi >= 30,
    textKey: 'BMI_GE_30'
  }
]

const MIN_BMI = 20
const MAX_BMI = 27



//   submit = (event) => {
//     event.preventDefault()
//     this.setState({loading: true})



//     if(this.state.weightGoal) {

//       this.props.mutate({
//         variables: {weightGoal: this.state.weightGoal}
//       }).then(({ data }) => {
//         this.setState({loading: false})
//         const res = data.user.updateMemberProperties.status
//         if(res === "success") {
//           this.props.refetch && this.props.refetch();
//           this.props.close && this.props.close();
//         }
//       }).catch((error) => {
//         this.setState({loading: false})
//         alert(error);
//       });
//     }
//   }








interface Props {
  close?: () => void;
  weightGoal: number;
  weight: number;
  height: number;
}

export const WeightGoalForm:React.FC<Props> = ({weightGoal: weightGoalInit, weight, height, close, ...props}) => {
  const [weightGoal, handleWeightGoal] = useState(weightGoalInit)

  const [saveGoals, {loading}] = useMutation(UPDATE_WEIGHT_GOAL, {
    onCompleted() {
      close();
    }
  });

  function calculateBMI(weight:number) {
    let tmp = height / 100;
    let bmi = tmp > 0 ? (weight / (tmp*tmp)).toFixed(1) : 0;

    return +bmi;
  }

  function  getBmiClass(bmi:number, styles:any) {
    return bmi > MIN_BMI && bmi <= MAX_BMI ? styles.goodBMI : styles.badBMI
  }

  function getTips(res, bmi) {
    const tips = TIPS_INFO.map(i => res[i.textKey] && {
      rule: i.rule,
      text: res[i.textKey]
    }).filter(_ => _).filter(i => i.rule(bmi))[0]

    return bmi > 0 ? tips : null
  }

  function  getBMIHandler(bmiGoal:number, bmiCurrent:number) {
    const goalBmiTips = getTips(profileSettings.goalBmiTips, bmiGoal);
    const currentBmiTips = getTips(profileSettings.currentBmiTips, bmiCurrent);

    return bmiGoal > 0 ? goalBmiTips.text.replace("$TARGET_BMI", bmiGoal) : currentBmiTips.text.replace("$TARGET_BMI", bmiCurrent)

  }

  const submit = async (event) => {
    event.preventDefault()

    const variables = {weightGoal: weightGoal}
    try {
      await saveGoals({variables, refetchQueries: [GET_USER_DATA]})
    } catch (error) {
      alert(error)
    }
  }

  const bmi = calculateBMI(weight);
  const bmiGoal = calculateBMI(weightGoal)

  const bmiTip = getBMIHandler(bmiGoal, bmi);

  return <form  className={formStyles.form} onSubmit={submit}>
    <div className={styles.bmiContainer}>
      <div className={styles.bmiWrapper}>
        <div className={styles.bmiResults}>
          <div className={styles.bmiItem}>
            <div className={styles.bmiCenter}>Din BMI er: {bmi}</div>
          </div>
          <div className={`${styles.bmiItem} ${ bmiGoal > 0 ? getBmiClass(bmiGoal, styles) : styles.blackBMI}`}>
            <div className={`${styles.bmiCenter}`}>Din BMI blir: {bmiGoal}</div>
          </div>
        </div>
      </div>
      <div className={styles.bmiDescriptions}>
        {bmiTip}
      </div>
    </div>
    <hr />
    <div className={formStyles.group}>
      <label><input type="number" required defaultValue={weightGoal} onChange={(e) => handleWeightGoal(+e.target.value)} autoComplete="off" /><span>Ønsket vekt</span></label>
    </div>
    <hr />
    <button disabled={loading} type="submit">Lagre</button>

  </form>
}

export default function WeightGoalButton(props) {
  return(
    <PopupButton  title="Mitt mål" show={props.show} {...props}>
      <WeightGoalForm {...props}  />
    </PopupButton>
  )
}
